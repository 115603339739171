import { addCommas, convertQrToNumber } from "src/lib/helpers";
import React, { Component } from "react";
import DataTable from "react-data-table-component";
import {
  Button,
  Modal, ModalHeader, ModalBody, ModalFooter,
  Form,
  Label,
  Input,
  FormGroup,
  FormFeedback,
  Spinner,
  FormText,
} from 'reactstrap';
import API from "src/api";
import NotifToast from "src/components/notificationModal/NotifToast";
import ConfirmModal from "src/components/notificationModal/ConfirmModal";

export default class ModalDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: {},
      disabled: true,
      buttonLoading: false,
      required: ['serial_range', 'reason'],
      body: {
        serial_range: [],
        reason: '',
      },
      notif: {
        show: false,
        title: '',
        message: '',
        status: true,
      },
      previewDatas: [],
      showModalConfirm: false,
    }
  }

  onChange = async (e) => {
    const name = e.target.name;
    const value = e.target.value;

    const newBody = this.state.body
    newBody[name] = value

    const error = this.state.error;
    if (!value) {
      error[name] = 'Field is required'
    }
    else {
      delete error[name]
    }

    if (name === 'serial_range') {
      if (this.state.downloadPath) {
        this.setState({
          downloadPath: null,
        });
      }

      // split by enter
      const serials = value.split(/[\n]+/);
      let previewDatas = [];
      let bodyUpdate = "";
      let total = 0;

      for (let i = 0; i < serials.length; i++) {
        previewDatas[i] = {};
        if (!serials[i]) continue;

        let qty = 0;

        const serialSpace = serials[i].split(/\s+/);
        if (serialSpace.length > 1) {
          serials[i] = serialSpace[serialSpace.length - 1];
        }

        if (serials[i].includes('-')) {
          const serialsSplit = serials[i].split('-');
          if (serialsSplit.length > 1) {
            const start = convertQrToNumber(serialsSplit[0]);
            const end = convertQrToNumber(serialsSplit[1]);
            qty = end - start + 1;
          } else {
            qty = 1;
          }
        }

        bodyUpdate += serials[i] + '\n';

        previewDatas[i]['no'] = i + 1;
        previewDatas[i]['serial'] = serials[i];
        previewDatas[i]['qty'] = qty;
        total += qty;
      }

      if (previewDatas.length > 1) {
        previewDatas.push({
          no: '',
          serial: 'Total',
          qty: total
        });
        newBody[name] = bodyUpdate;
      }

      this.setState({
        previewDatas: previewDatas.filter((data) => data.serial),
      });
    }

    this.setState({
      body: newBody,
      error: error
    });

    this.onValidation()
  }

  onValidation() {
    const asArray = Object.entries(this.state.body);
    const filtered = asArray.filter(([key, value]) => {
      if (Array.isArray(value)) {
        return value.length === 0 && this.state.required.includes(key)
      } else {
        return !value && this.state.required.includes(key)
      }
    });
    this.setState({
      disabled: filtered.length ? true : false,
    });
  }

  onSubmit() {
    this.setState({
      buttonLoading: true,
    });
    let body = { ...this.state.body };
    body['serial_range'] = body['serial_range'].split('\n');
    body['serial_range'] = body['serial_range'].filter((serial) => serial);

    // alert(JSON.stringify(body)); return;
    API.put('product/delete-range', body, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })
      .then((resp) => {
        const result = resp.data;
        this.setState({
          buttonLoading: false,
        });
        if (result.status) {
          this.props.refresh();
          this.onClose();
          this.setState({
            showModalConfirm: false,
            notif: {
              title: 'Success',
              status: result.status,
              message: result.message,
            },
          });

          setTimeout(() => {
            this.setState({
              notif: { show: false }
            });
          }, 1500);
        } else {
          this.setState({
            showModalConfirm: false,
          });
        }
      })
      .catch(err => {
        this.setState({
          buttonLoading: false,
          showModalConfirm: false,
          body: {},
          notif: {
            title: 'Failed',
            message: err,
            status: false,
          }
        });
      })
  }

  onClose = () => {
    if (this.state.buttonLoading) return;
    this.props.close();
    this.setState({
      error: {},
      disabled: true,
      buttonLoading: false,
      body: {
        start_serial: '',
        end_serial: '',
        reason: '',
      },
      notif: {
        show: false,
        title: '',
        message: '',
        status: true,
      },
      previewDatas: [],
    });
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.open} toggle={() => this.onClose()} size="lg">
          <ModalHeader toggle={() => this.onClose()}>Delete Products</ModalHeader>
          <ModalBody>
            <div className="row">
              <Form className="col-md-5">
                <FormGroup>
                  <Label>
                    Serial Range
                  </Label>
                  <Input
                    name="serial_range"
                    placeholder="Example: 001-002"
                    type="textarea"
                    onChange={this.onChange}
                    value={this.state.body['serial_range']}
                    required
                    invalid={this.state.error['serial_range'] ? true : false}
                  />
                  <FormText>Use enter to add multiples </FormText>
                  <FormFeedback >{this.state.error['serial_range']}</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label>
                    Reason
                  </Label>
                  <Input
                    name="reason"
                    placeholder="Enter reason"
                    type="text"
                    onChange={this.onChange}
                    value={this.state.body['reason']}
                    required
                    invalid={this.state.error['reason'] ? true : false}
                  />
                  <FormFeedback >{this.state.error['reason']}</FormFeedback>
                </FormGroup>
              </Form>
              <div className="col-md-7 border-l-2">
                <div className="text-lg text-muted">Preview Data</div>
                <DataTable
                  noHeader={true}
                  columns={[
                    {
                      name: 'No.',
                      selector: 'no',
                      width: '10%',
                    },
                    {
                      name: 'Range ID',
                      selector: 'serial',
                      sortable: true,
                      width: '50%'
                    },
                    {
                      name: 'Qty',
                      sortable: true,
                      width: '25%',
                      cell: data => addCommas(data.qty),
                    },
                  ]}
                  data={this.state.previewDatas}
                  pagination={false}
                  noDataComponent={"You have not entered a id range."}
                  customStyles={{
                    rows: {
                      style: {
                        minHeight: '30px', // override the row height
                        padding: '0 8px', // override the row padding
                      },
                    },
                    headCells: {
                      style: {
                        padding: '0 8px', // override the head cell padding
                      },
                    },
                    cells: {
                      style: {
                        padding: '0 8px', // override the cell padding
                      },
                    },
                  }}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className={`w-full d-flex justify-content- ${this.state.buttonLoading ? 'between' : 'between'}`}>
            <div className="d-flex gap-2">
              {
                <>
                  <Button color="info" onClick={() => this.setState({ showModalConfirm: true })} disabled={this.state.disabled}>
                    {!this.state.buttonLoading ? 'Submit' : <>Process <Spinner size="sm" /></>}
                  </Button>
                  {
                    this.state.buttonLoading ? null : <Button color="danger" onClick={() => this.props.close()}>Cancel</Button>
                  }
                </>
              }
            </div>
          </ModalFooter>
        </Modal>
        <NotifToast
          show={this.state.notif.show}
          close={() => this.setState({ notif: { show: false } })}
          title={this.state.notif.title}
          message={this.state.notif.message}
          status={this.state.notif.status}
        />
        <ConfirmModal
          open={this.state.showModalConfirm}
          close={() => this.setState({ showModalConfirm: false })}
          onClick={() => this.onSubmit()}
          method='onClick'
          text='Are you sure you want to delete this product, this action cannot be undone?'
        />
      </>
    )
  }
}
import React, { Component, useState } from 'react';
import DataTable from 'react-data-table-component';
import API from '../../api'
import constants from '../../lib/constants';
import {
    NavLink,
    Button,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Alert
} from 'reactstrap';
import LoadingBar from 'react-top-loading-bar';
import Select from 'react-select';
import _ from 'lodash'
import { _hasAccess } from '../../middleware/accessMiddleware'
import { Link } from 'react-router-dom';
import PreviewImage from 'src/components/elements/image/PreviewImage';
import ModalDelete from './_components/modalDelete';

class ListProduct extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
        this.toggleModalCertificate = this.toggleModalCertificate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getManufacturerOption = this.getManufacturerOption.bind(this);

        this.state = {
            menuCode: constants.MENU_LIST_PRODUCT_ID,
            scope: [],
            optionSkuList: [],
            selectedSkuList: [],
            manufacturer: [],
            emptyCert: 0,
            columns: [
                {
                    name: 'Id',
                    selector: 'product_id',
                    sortable: true
                },
                {
                    name: 'QR Code ID',
                    selector: 'qrcode_id36',
                    sortable: true
                },
                {
                    name: 'ProductID',
                    selector: 'product_code',
                    sortable: true
                },
                {
                    name: 'Manucaturer',
                    selector: 'name',
                    sortable: true
                },
                {
                    name: 'Pin',
                    selector: 'pin',
                    sortable: true,
                },
                {
                    name: 'SKU/Variant',
                    selector: 'batch_number',
                    sortable: true
                },
                {
                    name: 'Certificate & QRCode',
                    selector: 'certificate',
                    sortable: true,
                    cell: product =>
                        <div class="d-flex flex-row">
                            {product.certificate !== null ?

                                <button type="button" title="Certificate" className="btn btn-sm btn-outline-success" onClick={this.toggleModalCertificate.bind(this, product.certificate, "certificate")}>
                                    <li className="fa fa-file-pdf-o"></li>
                                </button>
                                : null
                            }
                            &nbsp;
                            <button type="button" title="QRCode" className="btn btn-sm btn-outline-danger" onClick={this.toggleModalCertificate.bind(this, product.qrcode, "qrcode")}>
                                <li className="fa fa-qrcode"></li>
                            </button>
                        </div>
                },
                {
                    name: 'Action',
                    selector: 'action',
                    sortable: false,
                    cell: product =>
                        <div class="d-flex flex-row gap-1">
                            <button type="button" className="btn btn-sm btn-outline-secondary" title="Detail Data" onClick={this.linkDetail.bind(this, product.product_id)}>
                                <li className="fa fa-eye"></li>
                            </button>
                            {
                                this.state.scope.includes('update') ?
                                    <button type="button" className="btn btn-sm btn-outline-success" title="Edit Data" onClick={this.linkEditValue.bind(this, product.product_id)}>
                                        <li className="fa fa-edit"></li>
                                    </button>
                                    : null
                            }
                            {
                                // this.state.scope.includes('delete') ?
                                //     <button type="button" className="btn btn-sm btn-outline-danger" title="Delete Data" onClick={this.toggle.bind(this, product.product_id)}>
                                //         <li className="fa fa-trash"></li>
                                //     </button>
                                //     : null
                            }
                        </div>
                }
            ],
            data: [],
            isModalDeleteOpen: false,
            alertSuccess: false,
            deleteProductId: "",
            paginationPerPage: 50,
            paginationTotalRows: 0,
            paginationPage: 1,
            isModalCertificateOpen: false,
            openedCertificateImg: "",
            printed: [],
            from: 0,
            to: 0,
            loadingData: true,
            manufacturerId: '',
            showModalDelete: false,
        };
    }

    componentDidMount() {
        this.checkAccess()
        this.getManufacturer();
        this.getListProduct()
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'read');
        console.log(access)
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    async checkAccess() {
        const access = await _hasAccess(this.state.menuCode, 'read');
        console.log(access)
        if (access) {
            let scope = [];
            access.scope.map(item => item.status == 1 ? (scope.push(...[item.name])) : null)

            this.setState({
                scope: scope
            })
            return;
        }

        this.props.history.push(`/unauthorized`);
    }

    getListProduct() {
        API.get('product', {
            params: {
                size: this.state.paginationPerPage,
                page: this.state.paginationPage,
                search: this.state.search,
                searchSku: this.state.searchSku,
                empty_cert: this.state.emptyCert,
                manufacturer_id: this.state.manufacturerId
            },
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
            .then(res => {
                console.log("List product", res.data);
                this.setState({
                    data: res.data.item,
                    paginationTotalRows: res.data.totalItem,
                    loadingData: false
                })
            })
            .catch(err => {
                console.log("Error", err)
            })
    }
    async getManufacturer() {
        let manufacturerOptions = await API.get('manufacturer/options', { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } })

        this.setState({
            manufacturer: manufacturerOptions.data,
        })

    }

    getManufacturerOption() {
        let items = [<option key={0} value={''} >Choose Manufacture</option>];
        let manufacturer = this.state.manufacturer;
        // console.log("List manufacturer", manufacturer)

        for (let i = 0; i < manufacturer.length; i++) {
            items.push(<option key={manufacturer[i].manufacturer_id} value={manufacturer[i].manufacturer_id} data-name={manufacturer[i].name} data-flag={manufacturer[i].flag_productvariant}>{manufacturer[i].name}</option>);
        }
        return items;
    }

    linkEditValue(id) {
        this.props.history.push("/product/edit/" + id);
    }

    toggle(id) {
        console.log("Id delete", typeof id)
        this.setState({
            isModalDeleteOpen: !this.state.isModalDeleteOpen,
        })

        if (typeof id == "number") {
            this.setState({
                deleteProductId: id
            })
        }
    }

    toggleModalCertificate(certificate, type) {
        console.log("certificate type", typeof certificate)
        this.setState({
            isModalCertificateOpen: !this.state.isModalCertificateOpen,
            openedCertificateImg: "",
            modalType: type
        })

        if (!this.state.isModalCertificateOpen && type === "certificate") {
            var stringCert = certificate.toString();
            var splitStringCert = stringCert.split("/").pop();

            // alert(JSON.stringify(certificate))
            this.setState({
                openedCertificateImg: certificate
            })
        } else {
            if (typeof certificate == "string") {
                this.setState({
                    openedCertificateImg: certificate
                })
            } else {
                this.setState({
                    openedCertificateImg: ""
                })
            }
        }

    }

    deleteProduct() {
        API.delete('product/' + this.state.deleteProductId)
            .then(res => {
                console.log("product deleted");
                this.getListProduct();
                this.toggle();
                this.toggleAlertSuccess();
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    toggleAlertSuccess() {
        this.setState({
            alertSuccess: !this.state.alertSuccess
        })

        setTimeout(() => {
            this.setState({
                alertSuccess: !this.state.alertSuccess
            })
        }, 2000)
    }

    linkDetail(id) {
        this.props.history.push("/product/" + id);
    }

    changePage(page) {
        console.log("page", page)
        this.setState({
            paginationPage: page
        }, () => {
            this.getListProduct();
        })
    }

    changeRowsPerPage(rowsPerPage) {
        this.setState({
            paginationPerPage: rowsPerPage
        }, () => {
            this.getListProduct();
        })
    }
    async handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'manufacturerId') {
            const manId = event.target.value;
            let url = 'product/getVariant/' + manId;
            const optionSkuList = await API.get(url, { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } });
            //mapping optionSkuList.data.result to [{value: result.id, label:result.variant}]
            const mappedOptions = optionSkuList.data.result.map(result => ({
                value: result.id,
                label: result.variant
            }));

            this.setState({
                optionSkuList: mappedOptions
            })
        }
    }
    onSelect = (selectedOption) => {
        this.setState({ searchSku: selectedOption.label, selectedSkuList: selectedOption });
    };

    //  handleChange (value) {
    //     // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //     this.setState({
    //         printed : value.selectedRows
    //     })
    //   };

    printProduct() {

        let printed = []

        for (let i = 0; i < this.state.printed.length; i++) {
            let temp = {}
            temp.product_id = this.state.printed[i].product_id
            temp.qrcode = this.state.printed[i].qrcode
            temp.token = this.state.printed[i].token
            temp.pin = this.state.printed[i].pin
            printed.push(temp)
        }

        console.log("this printed: ", printed)

        let body = {
            printed: printed
        }
        console.log("param", body)
        API.post('print/product', body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(response => {
                console.log("Sukses create Printed");
                this.LoadingBar.complete()

                setTimeout(() => {
                    window.location.href = "/product";
                }, 500);
            })
            .catch(error => {
                console.log("Error create Manufacturer", error);
            });
    }

    async printProductFromTo() {
        this.LoadingBar.continuousStart()
        const { from, to } = this.state

        var printing = _.map(this.state.data, function (product) {
            if (product.product_id >= from && product.product_id <= to) return product;
        });

        // Remove undefines from the array
        printing = _.without(printing, undefined)

        console.log(printing)
        let printed = []

        for (let i = 0; i < printing.length; i++) {
            let temp = {}
            temp.product_id = printing[i].product_id
            temp.qrcode = printing[i].qrcode
            temp.token = printing[i].token
            temp.pin = printing[i].pin
            printed.push(temp)
        }

        console.log("this printed: ", printed)

        let body = {
            printed: printed
        }
        console.log("param", body)
        API.post('print/product', body, { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('token')}` } })
            .then(response => {
                console.log("Sukses create Printed");
                this.LoadingBar.complete()

                setTimeout(() => {
                    window.location.href = "/product";
                }, 500);
            })
            .catch(error => {
                console.log("Error create Manufacturer", error);
            });
    }
    emptyCertData(data) {
        console.log(data.target.checked)
        const emptyCert = data.target.checked === true ? 1 : 0;
        this.setState({
            emptyCert: emptyCert
        })

    }
    searchQRCode(e) {
        this.setState({
            loadingData: true
        })
        e.preventDefault();
        API.get('product', {
            params: {
                size: this.state.paginationPerPage,
                page: this.state.paginationPage,
                search: this.state.search,
                searchSku: this.state.searchSku,
                empty_cert: this.state.emptyCert,
                manufacturer_id: this.state.manufacturerId
            },
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
            .then(res => {
                console.log("List product", res.data);
                this.setState({
                    data: res.data.item,
                    paginationTotalRows: res.data.totalItem,
                    loadingData: false
                })
            })
            .catch(err => {
                console.log("Error", err)
            })
    }

    CustomOption = ({ innerProps, label, data }) => (
        <div {...innerProps}>
            <div className='px-2'>
                <div>{data.variant}</div>
            </div>
        </div>
    );

    render() {

        console.log("data: ", this.state.from)

        return (
            <div className="content">
                <LoadingBar onRef={ref => (this.LoadingBar = ref)} />
                <div className="animated fadeIn">
                    <div className="row">

                        <div className="col-md-12">
                            <Alert
                                color="success"
                                isOpen={this.state.alertSuccess}
                            >
                                Product deleted successfully
                            </Alert>
                            <div className="card">
                                <div className="card-header vertical-middle d-flex justify-content-between">
                                    <strong className="card-title vertical-middle-p">List Product</strong>
                                    <div className='d-flex gap-2'>
                                        {
                                            this.state.scope.includes('delete') ?
                                                <Button type='button' size='sm' color="danger" onClick={() => this.setState({ showModalDelete: true })}>Delete Product</Button>
                                                : null
                                        }
                                        {
                                            this.state.scope.includes('create') ?
                                                <NavLink href="/product/add">
                                                    <Button color="info" size='sm' type='button'>Add ProductID</Button>
                                                </NavLink>
                                                : null
                                        }
                                        {
                                            this.state.printed.length > 0 &&
                                            <NavLink>
                                                <Button color="danger" size='sm' type='button' onClick={() => this.printProduct()}>Export Qr</Button>
                                            </NavLink>
                                        }
                                    </div>
                                </div>
                                <form onSubmit={(e) => this.searchQRCode(e)} className='mt-5 mr-3 ml-3 d-flex flex-row justify-items-center gap-2'>
                                    <div className=''>
                                        <select name="manufacturerId" id="manufacturerId" class="form-control" onChange={this.handleChange}>

                                            {this.getManufacturerOption()}
                                        </select>
                                    </div>
                                    <div className=''>
                                        <input
                                            id="search" name="search" type="text"
                                            className="form-control valid"
                                            data-val="true"
                                            data-val-required="search"
                                            autocomplete="search" aria-required="true"
                                            aria-invalid="false" aria-describedby="search"
                                            placeholder="Search by qrcode36 OR product_id"
                                            value={this.state.search}
                                            onChange={this.handleChange}
                                        // style={{ textTransform: 'uppercase' }}
                                        />
                                    </div>
                                    <div className='col-md-2'>
                                        <Select
                                            name="searchSku"
                                            // value={this.state.selectedSkuList}
                                            onChange={(e) => this.onSelect(e)}
                                            options={this.state.optionSkuList}
                                        />
                                    </div>
                                    <div className='d-flex justify-items-center'>
                                        <input class="form-check-input" type="checkbox" onChange={(e) => this.emptyCertData(e)} />Empty Cert
                                    </div>
                                    <div className='text-center'>
                                        <button type="button" onClick={(e) => this.searchQRCode(e)} className="btn btn-success w-100">Search</button>
                                    </div>
                                </form>
                                <div className="card-body">
                                    <DataTable
                                        columns={this.state.columns}
                                        data={this.state.data}
                                        // selectableRows 
                                        hover
                                        // entries={5}
                                        paginationPerPage={this.state.paginationPerPage}
                                        paginationRowsPerPageOptions={[50, 100, 200, 300]}
                                        paginationTotalRows={this.state.paginationTotalRows}
                                        pagination={true}
                                        onSelectedRowsChange={value => this.handleChange(value)}
                                        paginationServer={true}
                                        onChangePage={data => this.changePage(data)}
                                        onChangeRowsPerPage={data => this.changeRowsPerPage(data)}
                                        progressPending={this.state.loadingData}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.isModalDeleteOpen} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Are you sure?</ModalHeader>
                    <ModalFooter>
                        <Button color="info" onClick={this.deleteProduct}>Delete</Button>{' '}
                        <Button color="danger" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.isModalCertificateOpen && this.state.modalType != "certificate"} toggle={this.toggleModalCertificate}>
                    <ModalHeader toggle={this.toggleModalCertificate} className='bg-transparent'>
                        QR Code
                    </ModalHeader>
                    <ModalBody style={{ textAlign: "center" }} className='bg-transparent'>
                        <p hidden={this.state.openedCertificateImg != ""}>No Image found</p>
                        <img src={this.state.openedCertificateImg} ></img>
                    </ModalBody>
                    <ModalFooter className='bg-transparent'>
                        <Button color="danger" onClick={this.toggleModalCertificate}>Close</Button>
                    </ModalFooter>
                </Modal>
                <PreviewImage image={this.state.openedCertificateImg} show={this.state.isModalCertificateOpen && this.state.modalType == "certificate"} onClose={() => {
                    this.setState({ isModalCertificateOpen: false });
                }} />
                <ModalDelete
                    open={this.state.showModalDelete}
                    close={() => this.setState({ showModalDelete: false })}
                    refresh={() => this.getListProduct()}
                />
            </div>
        )
    }
}

export default ListProduct;